import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Logo from "../images/logo.png"


const Header = ({ siteTitle }) => {
  const [navOpen, setNavOpen] = React.useState("");

  return (
    <div className="Nav">
      <div className="maxWidth flex-row stay-flex">
        <div className="Nav__logo col">
          <a href="/">
            <img src={Logo} />
          </a>
        </div>
        <div className={`Nav__links col ${navOpen}`}>
          <div className="Nav__links__hamburger" onClick={() => setNavOpen(navOpen === "active" ? "" : "active")}>
            <div className="Nav__links__hamburger__bars bar1"></div>
            <div className="Nav__links__hamburger__bars bar2"></div>
            <div className="Nav__links__hamburger__bars bar3"></div>
            <div className="Nav__links__hamburger__bars bar4"></div>
          </div>
          <ul>
            <li>
              <Link to={"/about"} itemProp="url" className="">
                About
              </Link>
            </li>
            <li>
              <Link to={"/apps"} itemProp="url" className="">
                Apps
              </Link>
            </li>
            <li>
              <Link to={"/blog"} itemProp="url" className="">
                Blog
              </Link>
            </li>
            <li>
              <Link to={"/contact"} itemProp="url" className="">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
