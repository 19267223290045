import * as React from "react"

import Logo from "../images/logo.png"


// TODO add this Later
// [:div.Footer__form
//  [:h3 "Keep In Touch"]
//  [:div.Footer__form__container
//   [:input {:type "text" :placeholder "Email"}]
//   [:button.Button "Subscribe"]]]


const Footer = () => {

  return (
    <div className="Footer">
      <div className="Footer__links maxWidth flex-row">
        <div>
          <a href="/"><img src={Logo} /></a>
        </div>
        <div className="Footer__links__social">
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/Nitor-Fitness-100555152030259"><i className="fab fa-facebook"></i></a></li>
            <li><a target="_blank" href="https://twitter.com/FitnessNitor"><i className="fab fa-twitter"></i></a></li>
            <li><a target="_blank" href="https://www.instagram.com/nitorfitness/"><i className="fab fa-instagram"></i></a></li>
            <li><a target="_blank" href="https://www.pinterest.com/NitorFitness"><i className="fab fa-pinterest"></i></a></li>
            <li><p className="small">Copyright 2021 Nitor Fitness LLC</p></li>
          </ul>
        </div>
      </div>
    </div>
  )
}


export default Footer
